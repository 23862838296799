export const getUrlVars = (location) => {
  var vars = {};
  var parts = location.replace(
    /[?&]+([^=&]+)=([^&]*)/gi,
    function (m, key, value) {
      vars[key] = value;
    }
  );
  return vars;
};
